import config from "Config";
import { initializeApp }  from "firebase/app";
import * as firebaseAuthFunctions from "firebase/auth";

const app = initializeApp(config.firebaseConfig);

export const auth = firebaseAuthFunctions.getAuth(app)

export const EmailCredentialsProvider = firebaseAuthFunctions.EmailAuthProvider;
export const signIn = async (email: string, password: string) => await firebaseAuthFunctions.signInWithEmailAndPassword(auth, email, password);
export const reauthenticate = async (user: any, credentials: any) => await firebaseAuthFunctions.reauthenticateWithCredential(user, credentials);
export const createUserWithEmailAndPassword = async (email: string, password: string) => await firebaseAuthFunctions.createUserWithEmailAndPassword(auth, email, password);
export const onAuthStateChanged = (fn: firebaseAuthFunctions.NextOrObserver<firebaseAuthFunctions.User>) => firebaseAuthFunctions.onAuthStateChanged(auth, fn);
export const resetPassword = async (email: string) => await firebaseAuthFunctions.sendPasswordResetEmail(auth, email);
export const verifyPasswordReset = async (code: string) => await firebaseAuthFunctions.verifyPasswordResetCode(auth, code);
export const confirmPasswordReset = async (code: string, newPassword: string) => await firebaseAuthFunctions.confirmPasswordReset(auth, code, newPassword);
export const forceRefreshUser = async () => await auth.currentUser?.reload()
export const applyActionCode = async (code: string) => await firebaseAuthFunctions.applyActionCode(auth, code);
export const signOut = async () => await firebaseAuthFunctions.signOut(auth);

export const updatePassword = async (newPassword: string) => await firebaseAuthFunctions.updatePassword(auth.currentUser!, newPassword);
export const sendEmailVerification = async (user) => await firebaseAuthFunctions.sendEmailVerification(user)
export const loginWithCustomToken = async (token: string) => await firebaseAuthFunctions.signInWithCustomToken(auth, token)
