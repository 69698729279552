import The0MissionIcon from "../../Assets/Icons/The0MissionIcon";
import The0MissionText from "../../Assets/Icons/V2/The0MissionText";
import styles from "./TopMenu.module.scss";
import {Link} from "react-router-dom";

interface Props {
    children?: any;
    contentMaxWidth?: number;
}
const TopMenu = (props: Props) => {

    return (
        <nav className={styles.container}>
            <div style={{width: props.contentMaxWidth ?? '100%'}}>
                <Link className={styles.logo} to={"/v2/dashboard"}>
                    <The0MissionIcon height={45} width={45} variant={"light"} />
                    <The0MissionText />
                </Link>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
        </nav>
    )

}

export default TopMenu