interface Config {
    baseUrl: string,
    firebaseConfig: FirebaseConfig;
    calendly: CalendlyConfig;
    meterPointsUrl: string;
}

interface CalendlyConfig {
    url: string;
    accessToken: string;
}

interface FirebaseConfig {
    apiKey: string,
    authDomain: string,
    projectId: string,
    appId?: string
}

let config: Config

if (process.env.REACT_APP_ENV === 'Production') {
    config = {
        baseUrl: 'https://platform.the0mission.dk/api',
        firebaseConfig: {
            apiKey: "AIzaSyDKnkFCqXHLTTfGbCdUESH2mroHFxG-psE",
            authDomain: "the0mission-production.firebaseapp.com",
            projectId: "the0mission-production",
        },
        calendly: {
            url: "https://calendly.com/the0mission/30min",
            accessToken: "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzAyOTA4NTU1LCJqdGkiOiIyODIwMzgwOS1hYThlLTRlOWEtOWNkZC1mNTE3ZDM1OTFkZTUiLCJ1c2VyX3V1aWQiOiIzNzRjODIzNi1hYjQ1LTRkNmUtOGRiNi1hMWZmYWE0OWU1MmIifQ.ZJwZNz0U5kP8oaA9wDotDMzaiSDDo-FbAftXO6VRsuBNaIrxPW8pfi4kqILrlDBB988tg5qxlHlk4gNaUNUZOQ"
        },
        meterPointsUrl: 'https://grafana.the0mission.dk/d/d549a2d3-e134-44f8-8f26-f8b7b8075cc0/malepunkter-info-single?var-AgreementId={AgreementId}&var-FromDate={FromDate}&var-ToDate={ToDate}'
    }
}
else if (process.env.REACT_APP_ENV === 'Staging') {
    config = {
        baseUrl: 'https://platformdev.the0mission.dk/api',
        firebaseConfig: {
            apiKey: "AIzaSyCAwV0mBsg6lCaBu-91X35dGNWKpKVXNSs",
            authDomain: "the0mission-staging.firebaseapp.com",
            projectId: "the0mission-staging",
        },
        calendly: {
            url: "https://calendly.com/the0mission/30min",
            accessToken: "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzAyOTA4NTU1LCJqdGkiOiIyODIwMzgwOS1hYThlLTRlOWEtOWNkZC1mNTE3ZDM1OTFkZTUiLCJ1c2VyX3V1aWQiOiIzNzRjODIzNi1hYjQ1LTRkNmUtOGRiNi1hMWZmYWE0OWU1MmIifQ.ZJwZNz0U5kP8oaA9wDotDMzaiSDDo-FbAftXO6VRsuBNaIrxPW8pfi4kqILrlDBB988tg5qxlHlk4gNaUNUZOQ"
        },
        meterPointsUrl: 'https://grafana.the0mission.dk/d/d549a2d3-e134-44f8-8f26-f8b7b8075cc0/malepunkter-info-single?var-AgreementId={AgreementId}&var-FromDate={FromDate}&var-ToDate={ToDate}'
    }
}
else {
    config = {
        baseUrl: 'http://localhost:3000/api',
        firebaseConfig: {
            apiKey: "AIzaSyCAwV0mBsg6lCaBu-91X35dGNWKpKVXNSs",
            authDomain: "the0mission-staging.firebaseapp.com",
            projectId: "the0mission-staging",
        },
        calendly: {
            url: "https://calendly.com/morten-ricki-rasmussen/30min",
            accessToken: "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzAyOTA3MzQ5LCJqdGkiOiI5YjkxYjE5Ny1mMTk1LTRmN2YtODdlZS1hYjM3MWExNzMxNzIiLCJ1c2VyX3V1aWQiOiI3MDI0YTk4Yy0wYTdiLTQyMjQtOGE2Yy1mZTQyMjE5Y2E2NWQifQ.kyHlGsnUZqBpkNeHLr-xdo98hLhzKbCPtfdMVuF-ufGNuW-H5qJc24UUovZHj0phtUPPBND7D541q__kFlNJWQ"
        },
        meterPointsUrl: 'https://grafana.the0mission.dk/d/{AgreementId}/aftale-drilldown'
    }
}

export default config;
