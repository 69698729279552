import { useNavigate, useParams } from "react-router-dom";
import {
    useAddAuthorizationToAgreement,
    useSaveCustomUsageToAgreement,
    useAddUserToAgreement,
    useAgreement,
    useAuthorizationUrl,
    useSaveAcceptedTerms,
    useFindAuthorizations,
    useInvoices,
    useLoadUsageFromEloverblik,
    useDeleteCustomUsage, useDeleteAcceptedTerms, useDeleteAuthorization, useSaveAgreement,
} from "Apis/Admin/AgreementAdminApi";
import Back from "Components/Back";
import {Trans, useTranslation} from "react-i18next";
import styles from './ShowAgreementPage.module.scss';
import List from "Components/List";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import Modal from "Components/Modal";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { UserModel } from "Apis/Models/UserModel";
import Input from "Components/Input";
import Space from "Components/Space";
import moment from "moment";
import { CurrentAgreementUsageModel } from "Apis/Models/CurrentAgreementUsageModel";
import AgreementUsageChart from "Pages/Agreements/Agreement/Components/AgreementUsageChart";
import { formatNumber } from "Utils/NumberUtils";
import { EloverblikAuthorizationModel } from "Apis/Models/EloverblikAuthorizationModel";
import {resetPassword} from "../../../FirebaseSetup/auth";
import useToast from "../../../Hooks/useToast";
import {useSaveTerms, useTerms} from "../../../Apis/Admin/TermsApi";
import {AcceptedTermsModel, AgreementType} from "../../../Apis/Models/AgreementModel";
import {InvoiceModel} from "../../../Apis/Models/InvoiceModel";
import CreateTermsModal from "../../../Features/Terms/Components/CreateTermsModal";
import {CreateTermsModel} from "../../../Apis/Models/CreateTermsModel";
import ShowTermsModal from "../../../Features/Terms/Components/ShowTermsModal";
import Gap from "../../../Components/V2/Gap";
import Textarea from "../../../Components/Textarea";

const ShowAgreementPage = () => {
    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast, showErrorToast } = useToast();

    const { agreementId } = useParams<{ agreementId: string }>()
    
    const chartsDivRef = useRef<HTMLDivElement>(null)
    
    const { data: agreement } = useAgreement(agreementId!);
    const { data: previousInvoices } = useInvoices(agreementId!);
    const { data: terms } = useTerms();
    const { mutateAsync: updateAgreementMutation, isLoading: isUpdatingAgreement } = useSaveAgreement();
    const { mutateAsync: addUserMutation, isLoading: isAddingUser } = useAddUserToAgreement(agreementId!);
    const { mutateAsync: findAuthorizationsMutation, isLoading: isFindingAuthorizations } = useFindAuthorizations();
    const { mutateAsync: addAuthorizationMutation, isLoading: isAddingAuthorizations } = useAddAuthorizationToAgreement(agreementId!);
    const { mutateAsync: saveCustomUsageMutation, isLoading: isAddingCustomUsage } = useSaveCustomUsageToAgreement(agreementId!);
    const { mutateAsync: deleteCustomUsageMutation, isLoading: isDeletingCustomUsage } = useDeleteCustomUsage(agreementId!);
    const { mutateAsync: loadUsageFromEloverblikMutation, isLoading: isLoadingUsage } = useLoadUsageFromEloverblik(agreementId!);
    const { mutateAsync: saveAcceptedTerms, isLoading: isAcceptingTerms } = useSaveAcceptedTerms(agreementId!);
    const { mutateAsync: getAuthorizationLinkMutation, isLoading: isGettingLink } = useAuthorizationUrl(agreementId!);
    const { mutateAsync: createTermsMutation } = useSaveTerms();
    const { mutateAsync: deleteAcceptedTermsMutation, isLoading: isDeletingAcceptedTerms } = useDeleteAcceptedTerms(agreementId!);
    const { mutateAsync: deleteAuthorizationMutation, isLoading: isDeletingAuthorization } = useDeleteAuthorization(agreementId!);

    const [createCustomTermsModalVisible, setcreateCustomTermsModalVisible] = useState<boolean>(false);
    const [updateAgreementModal, setUpdateAgreementModalVisible] = useState<boolean>(false);
    const [addUserModalVisible, setAddUserModalVisible] = useState<boolean>(false);
    const [addAuthorizationModalVisible, setAuthorizationModalVisible] = useState<boolean>(false);
    const [customUsageModalVisible, setCustomUsageModalVisible] = useState<boolean>(false);
    const [startAgreementModalVisible, setStartAgreementModalVisible] = useState<boolean>(false);
    const [changeTermsModalVisible, setChangeTermsModalVisible] = useState<boolean>(false);
    const [deleteCustomUsageModalVisible, setDeleteCustomUsageModalVisible] = useState<boolean>(false);
    const [deleteAcceptedTersModalVisible, setDeleteAcceptedTersModalVisible] = useState<boolean>(false);
    const [deleteAuthorizationModalVisible, setDeleteAuthorizationModalVisible] = useState<boolean>(false);

    const [foundAuthorization, setFoundAuthorization] = useState<any>();
    const [selectedCustomUsage, setSelectedCustomUsage] = useState<CurrentAgreementUsageModel | undefined>()
    const [selectedTerms, setSelectedTerms] = useState<AcceptedTermsModel | undefined>()
    const [selectedAuthorization, setSelectedAuthorization] = useState<EloverblikAuthorizationModel | undefined>()

    const { control: updateAgreementControl, handleSubmit: handleUpdateAgreement } = useForm<{nickname: string}>();
    const { reset: resetUserForm, control: addUserControl, handleSubmit: handleAddUserSubmit } = useForm<UserModel>();
    const { control: startAgreementControl, handleSubmit: handleStartAgreementSubmit, register: registerStartAgreement } = useForm<AcceptedTermsModel>();
    const { reset: resetAuthorizationForm, control: addAuthorizationControl, handleSubmit: handleAuthorizationSubmit } = useForm<{ externalId: string }>();
    const { reset: resetCustomUsageForm, control: saveCustomUsageControl, handleSubmit: handleSaveCustomUsageSubmit, setError: setCustomUsageError } = useForm<CurrentAgreementUsageModel>();
    const { reset: resetChangeTermsForm, control: changeTermsControl, handleSubmit: handleChangeTermsSubmit, register: registerChangeTerms } = useForm<AcceptedTermsModel>();

    const updateAgreement = async (data: {nickname: string}) => {
        await updateAgreementMutation({...agreement!, ...data});

        closeUpdateAgreementModal();
    }

    const closeUpdateAgreementModal = () => {
        setUpdateAgreementModalVisible(false)
    }

    const addUser = async (data: UserModel) => {
        const response = await addUserMutation(data);

        await resetPassword(response.externalUserId);
        
        closeUserModal()
    }
    
    const closeUserModal = () => {
        resetUserForm({name: '', email: ''});
        setAddUserModalVisible(false);
    }
    
    const findAuthorizations = async (data: {externalId: string}) => {
        const response = await findAuthorizationsMutation(data);
        
        setFoundAuthorization(response);
    }
    
    const addAuthorization = async () => {
        await addAuthorizationMutation(foundAuthorization.id);
        
        closeAuthorizationModal();
    }
    
    const closeAuthorizationModal = () => {
        resetAuthorizationForm({externalId: ''});
        setFoundAuthorization(undefined);
        setAuthorizationModalVisible(false);
    }
    
    const saveCustomUsage = async (data: CurrentAgreementUsageModel) => {
        if (data.yearlyUsage % 1 !== 0) {
            setCustomUsageError("yearlyUsage", {type: "manual", message: t('agreement:setup.custom.yearlyUsageError')});
            return;
        }

        data = {
            ...selectedCustomUsage,
            ...data
        };

        if (data.to === "") {
            data.to = undefined;
        }
        
        await saveCustomUsageMutation(data);
        
        closeCustomUsageModel();
    }

    const openDeleteCustomUsageModal = (data: CurrentAgreementUsageModel) => {
        setSelectedCustomUsage(data);
        setDeleteCustomUsageModalVisible(true);
    }

    const closeDeleteCustomUsageModal = () => {
        setSelectedCustomUsage(undefined);
        setDeleteCustomUsageModalVisible(false);
    }

    const deleteCustomUsage = async () => {
        if (!!selectedCustomUsage) {
            await deleteCustomUsageMutation(selectedCustomUsage);

            closeDeleteCustomUsageModal();
        }
    }
    
    const closeCustomUsageModel = () => {
        setSelectedCustomUsage(undefined);
        setCustomUsageModalVisible(false);
        resetCustomUsageForm({from: '', to: '', yearlyUsage: 0});
    }

    const selectTerms = (data: AcceptedTermsModel) => {
        setSelectedTerms(data);
        resetChangeTermsForm(data);
        setChangeTermsModalVisible(true)
    }

    const changeTerms = async (data: AcceptedTermsModel) => {
        data = {
            ...selectedTerms,
            ...data
        };

        if (data.validTo === "") {
            data.validTo = undefined;
        }

        await saveAcceptedTerms(data);

        closeChangeTermsModal();
    }

    const openDeleteAcceptedTermsModal = (data: AcceptedTermsModel) => {
        setSelectedTerms(data);
        setDeleteAcceptedTersModalVisible(true);
    }

    const deleteAcceptedTerms = async () => {
        if (!!selectedTerms) {
            await deleteAcceptedTermsMutation(selectedTerms);
            closeDeleteAcceptedTermsModal();
        }
    }

    const closeDeleteAcceptedTermsModal = () => {
        setSelectedTerms(undefined);
        setDeleteAcceptedTersModalVisible(false);
    }

    const createCustomTerms = async (data: CreateTermsModel) => {
        data.isCustomTerms = true;

        const newTerms = await createTermsMutation(data);

        await saveAcceptedTerms({
            validFrom: data.from,
            agreementTermsId: newTerms.id
        });

        setcreateCustomTermsModalVisible(false);
    }

    const closeChangeTermsModal = () => {
        setSelectedTerms(undefined)
        setChangeTermsModalVisible(false);
        resetChangeTermsForm({validFrom: '', validTo: '', agreementTermsId: undefined});
    }
    
    const gotoAgreement = () => {
        navigate(`/agreements/${agreementId}/dashboard`);
    }
    
    const getAgreementStatusText = () => {
        switch (agreement?.agreementStatus) {
            case 'Pending':
                return t('agreement:status.pending');
            case 'Active':
                return t('agreement:status.active');
            case 'Expired':
                return t('agreement:status.expired');
        }
    }
    
    const startAgreement = async (data: AcceptedTermsModel) => {
        await saveAcceptedTerms(data);
        
        closeStartAgreementModal();
    }
    
    
    const closeStartAgreementModal = () => {
        setStartAgreementModalVisible(false);
    }
    
    const showCertificate = () => {
        window.open(`/api/certificate/${agreement!.id}`, '_blank');
    }
    
    const createNewAuthorization = async () => {
        const url = await getAuthorizationLinkMutation();
    
        navigator.clipboard.writeText(url.url);
        
        showSuccessToast(t('agreement:copied'));
    }

    const getAgreementTypeText = (agreement: AgreementType) => {
        switch (agreement) {
            case "Custom":
                return t('agreement:custom');
            case "Match":
                return t('agreement:match');
            case "MatchAndCustom":
                return t('agreement:matchAndCustom');
        }
    }

    const loadUsage = async () => {
        if (!!agreement && agreement.eloverblikAuthorizations.length === 0) {
            showErrorToast(t('errors:noEloverblikAuthorizations'))
        } else {
            await loadUsageFromEloverblikMutation();
        }
    }

    const updateCustomUsage = (data: CurrentAgreementUsageModel) => {
        setSelectedCustomUsage(data);
        resetCustomUsageForm(data);

        setCustomUsageModalVisible(true);
    }

    const hasMissingPeriod = () => {
        let missingPeriods: {from, to}[] = [];

        if (!agreement || agreement?.customAgreementUsages.length === 0) {
            return missingPeriods;
        }

        let reversedArray = agreement.customAgreementUsages.slice().reverse();

        for (let i = 0; i < agreement.customAgreementUsages.length ; i++) {
            if (i === agreement.customAgreementUsages.length - 1) {
                continue;
            }

            let current = reversedArray[i];
            let next = reversedArray[i + 1];

            if (moment(current.to).add(1, 'day').isBefore(next.from)) {
                missingPeriods.push({
                    from: moment(current.to).add(1, 'day').format('DD-MM-YYYY'),
                    to: moment(next.from).subtract(1, 'day').format('DD-MM-YYYY')
                });
            }
        }

        return missingPeriods;
    }

    const openDeleteAuthorizartionModal = (data: EloverblikAuthorizationModel) => {
        setSelectedAuthorization(data);

        setDeleteAuthorizationModalVisible(true);
    }

    const closeDeleteAuthorizationModal = () => {
        setSelectedAuthorization(undefined);
        setDeleteAuthorizationModalVisible(false);
    }

    const deleteAuthorization = async () => {
        if (!!selectedAuthorization) {
            await deleteAuthorizationMutation(selectedAuthorization);
            closeDeleteAuthorizationModal();
        }
    }

    return (
        <div>
            <Back text={`${t('agreement')} - ${agreement?.nickname}`}/>

            <div className={styles.section}>

                <div className={styles.details}>
                    <div>
                        <span>
                            {t('companyName')}:
                        </span>
                        <span>
                            {agreement?.companyName}
                        </span>
                    </div>
                    <div>
                        <span>
                            {t('nickname')}:
                        </span>
                        <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                              onClick={() => setUpdateAgreementModalVisible(true)}>
                            {agreement?.nickname}
                        </span>
                    </div>
                    <div>
                        <span>
                            {t('vatNumber')}:
                        </span>
                        {agreement?.vatNumber}
                    </div>
                    <div>
                        <span>
                            {t('contactPerson')}:
                        </span>
                        {agreement?.name}
                    </div>
                    <div>
                        <span>
                            {t('buyerEmail')}:
                        </span>
                        {agreement?.users[0]?.email}
                    </div>
                    <div>
                        <span>
                            {t('phone')}:
                        </span>
                        {agreement?.phoneNumber}
                    </div>
                    <div>
                        <span>
                            {t('invoiceEmail')}:
                        </span>
                        {agreement?.invoiceEmail}
                    </div>
                    <div>
                        <span>
                            {t('address')}:
                        </span>
                        {agreement?.address}, {agreement?.zipCode} {agreement?.city}
                    </div>
                    <div>
                        <span>
                            {t('createdBy')}:
                        </span>
                        {agreement?.createdBy?.name ?? agreement?.createdBy?.email}
                    </div>
                    <div>
                        <span>
                            {t('signedAt')}:
                        </span>
                        {agreement?.agreementSignedAt ? moment(agreement?.agreementSignedAt).format('DD.MM.YYYY') : t('notSigned')}
                    </div>
                    <div>
                        <span>
                            {t('status')}:
                        </span>
                        {getAgreementStatusText()}
                    </div>
                    <div>
                        <span>
                            {t('invoicingStarted')}:
                        </span>
                        {!!agreement && (
                            <>{agreement?.acceptedTerms.length > 0 ? `${t('yes')} - ${moment(agreement?.acceptedTerms[0].validFrom).format('DD.MM.YYYY')}` : t('no')}</>
                        )}
                    </div>
                    <div>
                        <span>
                            {t('customerNumber')}:
                        </span>
                        {agreement?.customerNumber}
                    </div>
                    <div>
                        <span>
                            {t('currentTerms')}:
                        </span>
                        {!agreement?.currentTerms && (t('noTermsAccepted'))}
                        {!!agreement?.currentTerms && (
                            <>
                                {t(`agreement:${agreement?.currentTerms?.agreementTerms.agreementType}`)},
                                Version: {agreement?.currentTerms?.agreementTerms.version} - {t('unitSalesPrice')}: {formatNumber(agreement?.currentTerms?.agreementTerms.unitSalesPrice)} DKK
                            </>
                        )}
                    </div>

                </div>

                {agreement?.agreementStatus !== "Active" && (
                    <div style={{paddingTop: 40}}>
                        <Space>
                            <Button onClick={_ => setStartAgreementModalVisible(true)}>{t('startAgreement')}</Button>
                            <Button onClick={_ => setcreateCustomTermsModalVisible(true)}>{t('startAgreementWithCustomTerms')}</Button>
                        </Space>
                    </div>
                )}

                <div style={{paddingTop: 40}}>
                    <Button onClick={gotoAgreement}>{t('gotoAgreement')}</Button>
                </div>

                {agreement?.agreementStatus === "Active" && (
                    <div style={{paddingTop: 40}}>
                        <Button onClick={showCertificate}>{t('showCertificatePage')}</Button>
                    </div>
                )}

                <Modal title={t('updateAgreement')} visible={updateAgreementModal} onCancel={closeUpdateAgreementModal}>
                    <form onSubmit={handleUpdateAgreement(updateAgreement)}>
                        <Space size={"large"} direction={"column"}>
                            <div className={"w-100"}>
                                <Input name={"nickname"} control={updateAgreementControl} label={t('nickname')}
                                       placeholder={t('nickname')} defaultValue={agreement?.nickname}/>
                            </div>

                            <div className={"w-100"}>
                                <Input name={"name"} control={updateAgreementControl} label={t('contactPerson')}
                                       placeholder={t('contactPerson')} defaultValue={agreement?.name}/>
                            </div>

                            <div className={"w-100"}>
                                <Input name={"address"} control={updateAgreementControl} label={t('address')}
                                       rules={{required: true}} defaultValue={agreement?.address}/>
                            </div>

                            <div className={"w-100"}>
                                <Input name={"zipCode"} control={updateAgreementControl} label={t('zipCode')}
                                       rules={{required: true}} defaultValue={agreement?.zipCode}/>
                            </div>

                            <div className={"w-100"}>
                                <Input name={"city"} control={updateAgreementControl} label={t('city')}
                                       rules={{required: true}} defaultValue={agreement?.city}/>
                            </div>

                            <FlexRow justify={"center"}>
                                <Button loading={isUpdatingAgreement} disabled={isUpdatingAgreement}
                                        type={"submit"}>{t('updateAgreement')}</Button>
                            </FlexRow>
                        </Space>
                    </form>
                </Modal>
            </div>

            <div className={styles.section}>
                <div className={styles.padding} ref={chartsDivRef}>
                    {!!agreement && (
                        <AgreementUsageChart agreement={agreement} width={chartsDivRef.current?.clientWidth}/>
                    )}
                </div>

                <Button disabled={isLoadingUsage} loading={isLoadingUsage}
                        onClick={loadUsage}>{t('loadFromEloverblik')}</Button>
            </div>


            <div className={styles.section}>
                <FlexRow justify={"space-between"} align={"center"} className={styles.padding}>
                    <div className={styles.headLine}>
                        {t('users')}
                    </div>
                    <div>
                        <Button onClick={_ => setAddUserModalVisible(true)} color={"link"}>{'+' + t('addUser')}</Button>
                    </div>
                </FlexRow>

                <div className={styles.users}>
                    <List data={agreement?.users} columns={[
                        {
                            dataIndex: 'email',
                            title: t('email'),
                        },
                    ]} emptyText={t('noUsersAttached')} keyDataIndex={"id"}
                    />
                </div>

                <Modal title={t('addUser')} visible={addUserModalVisible} onCancel={closeUserModal}>
                    <form onSubmit={handleAddUserSubmit(addUser)}>
                        <Space size={"large"} direction={"column"}>
                            <div className={"w-100"}>
                                <Input name={"email"} control={addUserControl} label={t('email')}
                                       placeholder={t('email')}/>
                            </div>

                            <FlexRow justify={"center"}>
                                <Button loading={isAddingUser} disabled={isAddingUser}
                                        type={"submit"}>{t('addUser')}</Button>
                            </FlexRow>
                        </Space>
                    </form>
                </Modal>
            </div>

            <div className={styles.section}>
                <FlexRow justify={"space-between"} align={"center"} className={styles.padding}>
                    <div className={styles.headLine}>
                        {t('authorizations')}
                    </div>
                    <div>
                        <Space direction={"row"} size={"large"}>
                            <Button loading={isGettingLink} onClick={_ => createNewAuthorization()}
                                    color={"link"}>{'+' + t('getNewLink')}</Button>
                            <Button onClick={_ => setAuthorizationModalVisible(true)}
                                    color={"link"}>{'+' + t('addExistingAuthorization')}</Button>
                        </Space>
                    </div>
                </FlexRow>

                <div className={styles.users}>
                    <List data={agreement?.eloverblikAuthorizations} columns={[
                        {
                            dataIndex: 'externalId',
                            title: t('agreementExternalId'),
                        },
                        {
                            dataIndex: 'status',
                            title: t('status'),
                        },
                        {
                            dataIndex: 'customerKey',
                            title: t('customerKey'),
                        },
                        {
                            dataIndex: 'customerCVR',
                            title: t('vatNumber'),
                        },
                        {
                            dataIndex: 'customerName',
                            title: t('customerName'),
                        },
                        {
                            dataIndex: 'customerName',
                            title: t('functions'),
                            customRender: (value, record: EloverblikAuthorizationModel) => {
                                if (record.status !== "Pending") {
                                    return (
                                        <Gap size={"small"}>
                                            <Button color={"link"} onClick={() => openDeleteAuthorizartionModal(record)}>{t('delete')}</Button>
                                        </Gap>
                                    )
                                }

                                return (
                                    <>
                                        <Button color={"link"} onClick={() => {
                                            navigator.clipboard.writeText(record.authorizationUrl);
                                            showSuccessToast(t('agreement:copied'));
                                        }}>{t('agreement:copyUrl')}</Button>
                                    </>
                                )
                            }
                        },
                    ]} emptyText={t('noAuthorizationsAttached')} keyDataIndex={"id"}
                    />
                </div>

                <Modal title={t('removeAuthorization')} visible={deleteAuthorizationModalVisible} onCancel={closeDeleteAuthorizationModal}>
                    <Space>
                        <div className={"w-100"}>
                            <Trans i18nKey="deleteAuthorizationsConfirmation" values={{externalId: selectedAuthorization?.externalId}} />
                        </div>

                        <FlexRow justify={"end"}>
                            <Button color={"danger"} disabled={isDeletingAcceptedTerms} onClick={deleteAuthorization} loading={isDeletingAuthorization}>{t('delete')}</Button>
                        </FlexRow>
                    </Space>
                </Modal>

                <Modal title={t('addAuthorization')} visible={addAuthorizationModalVisible}
                       onCancel={closeAuthorizationModal}>
                    <form onSubmit={handleAuthorizationSubmit(findAuthorizations)}>
                        {!foundAuthorization && (
                            <Space size={"large"} direction={"column"}>
                                <div className={"w-100"}>
                                    <Input name={"externalId"} control={addAuthorizationControl} label={t('externalId')}
                                           placeholder={t('externalId')}/>
                                </div>

                                <FlexRow justify={"center"}>
                                    <Button loading={isFindingAuthorizations} disabled={isFindingAuthorizations}
                                            type={"submit"}>{t('findEloverblikAuthorization')}</Button>
                                </FlexRow>
                            </Space>
                        )}

                        {!!foundAuthorization && (
                            <Space size={"large"} direction={"column"}>

                                <div>
                                    {t('authorizationFound')}
                                </div>
                                <div>
                                    Fuldmagts Id: {foundAuthorization.id} - {foundAuthorization.customerName}
                                </div>

                                <FlexRow justify={"center"}>
                                    <Button loading={isAddingAuthorizations} disabled={isAddingAuthorizations}
                                            onClick={addAuthorization}>{t('addAuthorizationToAgreement')}</Button>
                                </FlexRow>
                            </Space>
                        )}
                    </form>
                </Modal>
            </div>

            <div className={styles.section}>
                <FlexRow justify={"space-between"} align={"center"} className={styles.padding}>
                    <div className={styles.headLine}>
                        {t('customUsage')}

                        {hasMissingPeriod().map((period, index) => (
                            <div key={index} className={styles.missingPeriod}>
                                {t('missingCustomUsagrFrom')}: {period.from} {t('to')} {period.to}
                            </div>
                        ))}
                    </div>
                    <div>
                        <Button onClick={() => setCustomUsageModalVisible(true)} color={"link"}>{'+' + t('addCustomUsage')}</Button>
                    </div>
                </FlexRow>

                <div className={styles.users}>
                    <List data={agreement?.customAgreementUsages} columns={[
                        {
                            dataIndex: 'from',
                            title: t('from'),
                            textRender: (value) => moment(value).format('DD-MM-YYYY')
                        },
                        {
                            dataIndex: 'to',
                            title: t('to'),
                            textRender: (value) => !!value ? moment(value).format('DD-MM-YYYY') : ''
                        },
                        {
                            dataIndex: 'yearlyUsage',
                            title: t('yearlyUsage'),
                        },
                        {
                            dataIndex: 'periodUsage',
                            title: t('periodUsage'),
                        },
                        {
                            title: t('functions'),
                            customRender: (value, data) => (
                                <Gap size={"small"}>
                                    <Button color={"link"} onClick={() => openDeleteCustomUsageModal(data)}>{t('delete')}</Button>
                                    <Button color={"link"} onClick={() => updateCustomUsage(data)}>{t('update')}</Button>
                                </Gap>
                            ),
                        },
                    ]} emptyText={t('noCustomUsageAttached')} keyDataIndex={"id"}
                    />
                </div>

                <Modal title={t('addCustomUsage')} visible={customUsageModalVisible} onCancel={closeCustomUsageModel}>
                    <form onSubmit={handleSaveCustomUsageSubmit(saveCustomUsage)}>
                        <Space size={"large"} direction={"column"}>

                            <div className={"w-100"}>
                                <Input name={"from"} type={"date"} control={saveCustomUsageControl} label={t('from')} placeholder={t('from')} rules={{required: true}} />
                            </div>

                            <div className={"w-100"}>
                                <Input name={"to"} type={"date"} control={saveCustomUsageControl} label={t('to')} placeholder={t('to')}/>
                            </div>

                            <div className={"w-100"}>
                                <Input min="1" step="1" defaultValue={0} name={"yearlyUsage"} control={saveCustomUsageControl} label={t('yearlyUsageInMwh')} placeholder={t('yearlyUsageInMwh')} rules={{required: true}}/>
                            </div>

                            <div className={"w-100"}>
                                <Input min="1" step="1" defaultValue={0} name={"periodUsage"} control={saveCustomUsageControl} label={t('periodUsageInMwh')} placeholder={t('periodUsageInMwh')} rules={{required: true}}/>
                            </div>

                            <FlexRow justify={"center"}>
                                <Button loading={isAddingCustomUsage} disabled={isAddingCustomUsage}
                                        type={"submit"}>{t('addCustomUsage')}</Button>
                            </FlexRow>
                        </Space>
                    </form>
                </Modal>

                <Modal title={t('deleteCustomAgreementUsage')} visible={deleteCustomUsageModalVisible}
                       onCancel={closeDeleteCustomUsageModal}>
                    <Space>
                        <div className={"w-100"}>
                            {!!selectedCustomUsage && (
                                <Trans i18nKey="deleteCustomUsageConfirmation" values={{from: selectedCustomUsage.from, yearlyUsage: selectedCustomUsage.yearlyUsage > 0 ? selectedCustomUsage.yearlyUsage : selectedCustomUsage.periodUsage}} />
                            )}
                        </div>

                        <FlexRow justify={"end"}>
                            <Button color={"danger"} disabled={isDeletingCustomUsage} onClick={deleteCustomUsage} loading={isDeletingCustomUsage}>{t('delete')}</Button>
                        </FlexRow>
                    </Space>
                </Modal>
            </div>

            <div className={styles.section}>
                <FlexRow justify={"space-between"} align={"center"} className={styles.padding}>
                    <div className={styles.headLine}>
                        {t('terms:acceptedTerms')}

                        {agreement?.acceptedTerms.filter(x => !x.validTo).length === 0 && (
                            <div className={styles.missingPeriod}>
                                {t('noOpenAcceptedTerms')}
                            </div>
                        )}
                    </div>
                    <div>
                        <Space>
                            <Button onClick={_ => setcreateCustomTermsModalVisible(true)} color={"link"}>{t('createCustomTerms')}</Button>
                            <Button onClick={_ => setChangeTermsModalVisible(true)} color={"link"}>{t('changeTerms')}</Button>
                        </Space>
                    </div>
                </FlexRow>

                <div className={styles.users}>
                    <List data={agreement?.acceptedTerms} columns={[
                        {
                            dataIndex: 'validFrom',
                            title: t('from'),
                            size: 'min-content',
                            customRender: (value) => <div style={{whiteSpace:'nowrap'}}>{moment(value).format('DD-MM-YYYY')}</div>
                        },
                        {
                            dataIndex: 'validTo',
                            title: t('to'),
                            size: 'min-content',
                            customRender: (value) => !!value ? <div style={{whiteSpace:'nowrap'}}>{moment(value).format('DD-MM-YYYY')}</div> : <></>
                        },
                        {
                            dataIndex: 'acceptedTerms',
                            title: t('terms:terms'),
                            textRender: (_, record) => `${record.agreementTerms?.name ?? ''} - ${getAgreementTypeText(record.agreementTerms.agreementType)} ${record.agreementTerms.isCustomTerms ? '' : `- Version: ${record?.agreementTerms.version}`}`
                        },
                        {
                            dataIndex: 'acceptedTerms',
                            title: t('terms:unitSalesPricePrMwh'),
                            textRender: (_, record) => `${formatNumber(record?.agreementTerms.unitSalesPrice)} DKK`
                        },
                        {
                            title: t('functions'),
                            customRender: (value, data) => (
                                <>
                                    <Button color={"link"} onClick={() => openDeleteAcceptedTermsModal(data)}>{t('delete')}</Button>
                                    <Button color={"link"} onClick={() => selectTerms(data)}>{t('update')}</Button>
                                    <ShowTermsModal acceptedTerms={data} button={(
                                        <Button color={"link"}>{t('terms:showTerms')}</Button>
                                    )}/>
                                </>
                            ),
                        },
                    ]} emptyText={t('noTermsAccepted')} keyDataIndex={"id"} />
                </div>

                <Modal title={t('changeAgreementTermsHeader')} visible={changeTermsModalVisible} onCancel={closeChangeTermsModal}>
                    <form onSubmit={handleChangeTermsSubmit(changeTerms)}>
                        <Space size={"large"} direction={"column"}>

                            <div className={"w-100"}>
                                <Input name={"validFrom"} type={"date"} control={changeTermsControl}
                                       label={t('validFrom')} placeholder={t('changeTermsFrom')}
                                       rules={{required: true}}/>
                            </div>

                            <div className={"w-100"}>
                                <Input name={"validTo"} type={"date"} control={changeTermsControl} label={t('validTo')}
                                       placeholder={t('validTo')}/>
                            </div>

                            <div className={"w-100"}>
                                <Textarea rows={10} name={"agreementText"} control={changeTermsControl} label={t('agreementText')} placeholder={t('agreementText')}/>
                            </div>

                            {!selectedTerms?.id && (
                                <div className={"w-100"}>
                                    <label className={`label`}>{t('terms')}</label>
                                    <select className={styles.select} {...registerChangeTerms('agreementTermsId')}>
                                        {terms?.filter(x => !x.isCustomTerms).map((term) => (
                                            <option key={term.id}
                                                    value={term.id}>{term.name ? `${term.name} - ` : ''} {getAgreementTypeText(term.agreementType)},
                                                Version {term.version} - {t('terms:unitSalesPricePrMwh')}: {term.unitSalesPrice}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <FlexRow justify={"center"}>
                                <Button loading={isAcceptingTerms} disabled={isAcceptingTerms}
                                        type={"submit"}>{t('changeTerms')}</Button>
                            </FlexRow>
                        </Space>
                    </form>
                </Modal>

                <Modal title={t('deleteAcceptedTerms')} visible={deleteAcceptedTersModalVisible}
                       onCancel={closeDeleteAcceptedTermsModal}>
                <Space>
                        <div className={"w-100"}>
                            <Trans i18nKey="deleteAcceptedTermsConfirmation" values={{from: selectedTerms?.validFrom, terms: selectedTerms?.agreementTerms.name}} />
                        </div>

                        <FlexRow justify={"end"}>
                            <Button color={"danger"} disabled={isDeletingAcceptedTerms} onClick={deleteAcceptedTerms} loading={isDeletingAcceptedTerms}>{t('delete')}</Button>
                        </FlexRow>
                    </Space>
                </Modal>
            </div>

            <div className={styles.section}>
                <FlexRow justify={"space-between"} align={"center"} className={styles.padding}>
                <div className={styles.headLine}>
                        {t('invoices')}
                    </div>
                    <div>

                    </div>
                </FlexRow>

                <div className={styles.users}>
                    <List data={previousInvoices} columns={[
                        {
                            dataIndex: "economicInfo",
                            title: t('invoiceStatus'),
                            customRender: (_, record: InvoiceModel) => (
                                <FlexRow direction={"column"} align={'start'} justify={"center"}>
                                    {!!record.economicInfo.bookedAt && (
                                        <>
                                            {t('booked')}
                                        </>
                                    )}
                                    {!record.economicInfo.bookedAt && !!record.economicInfo.createdAt && (
                                        <>
                                            {t('draft')}
                                        </>
                                    )}
                                </FlexRow>
                            )
                        },
                        {
                            dataIndex: 'invoiceDate',
                            title: t('date'),
                            textRender: (value) => moment(value).format('DD-MM-YYYY')
                        },
                        {
                            dataIndex: 'invoiceTotal',
                            title: t('total'),
                            textRender: (value) => formatNumber(value) + ' kr',
                        },
                    ]} emptyText={t('noInvoicesFound')} keyDataIndex={"id"}
                    />
                </div>
            </div>

            <Modal title={t('startAgreementModalHeader')} visible={startAgreementModalVisible} onCancel={closeStartAgreementModal}>
                <form onSubmit={handleStartAgreementSubmit(startAgreement)}>
                    <Space size={"large"} direction={"column"}>

                        <div className={"w-100"}>
                            <Input name={"validFrom"} type={"date"} control={startAgreementControl} label={t('from')} placeholder={t('invoiceFrom')}/>
                        </div>

                        <select className={styles.select} {...registerStartAgreement('agreementTermsId')}>
                            {terms?.filter(x => !x.isCustomTerms).map((term) => (
                                <option key={term.id} value={term.id}>{getAgreementTypeText(term.agreementType)}, Version {term.version} - {t('terms:unitSalesPricePrMwh')}: {term.unitSalesPrice}</option>
                            ))}
                        </select>

                        <FlexRow justify={"center"}>
                            <Button loading={isAcceptingTerms} disabled={isAcceptingTerms} type={"submit"}>{t('startAgreement')}</Button>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>

            <CreateTermsModal onSave={createCustomTerms} onCancel={() => setcreateCustomTermsModalVisible(false)} visible={createCustomTermsModalVisible} isCustomTerms terms={terms ?? []} />
        </div>
    )
}

export default ShowAgreementPage
