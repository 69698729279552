import styles from './InvoicesPage.module.scss'
import {useTranslation} from "react-i18next";
import {StyledText} from "../../../Components/V2/Text";
import Gap from "../../../Components/V2/Gap";
import Box from 'Components/V2/Box';
import Table from 'Components/V2/Table/Table';
import moment from "moment/moment";
import ReportIcon from "../../../Assets/Icons/V2/ReportIcon";
import DownloadIcon from "../../../Assets/Icons/V2/DownloadIcon";
import {toast} from "react-toastify";
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import {AcceptedTermsModel} from "../../../Apis/Models/AgreementModel";
import {getAcceptedTermsPdf} from "../../../Apis/AgreementApi";
import Separator from "../../../Components/V2/Separator";
import React from "react";
import useToast from "../../../Hooks/useToast";

const AcceptedTermsPage = () => {

    const { t } = useTranslation();

    const { showErrorToast } = useToast();

    const selectedAgreement = useSelectedAgreement();

    const downloadContract = async (acceptedTerms: AcceptedTermsModel) => {
        const message = t('donwloadingAcceptedTerms');

        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });

        try {
            await getAcceptedTermsPdf(selectedAgreement!, acceptedTerms);
        }
        catch (e: any) {
            console.log(e)
            showErrorToast(e.data.message)
        }
    }

    return (
         <Box className={styles.container}>
            <Gap direction={"vertical"} size={"medium"}>
                <Gap direction={"vertical"} size={"small"}>
                    <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('acceptedTerms')}</StyledText>
                    <AgreementSelector requireSelect position={"Under Left"} />
                </Gap>

                <Separator/>

                {!!selectedAgreement && (
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    {t('period')}
                                </th>
                                <th>
                                    {t('download')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {selectedAgreement?.acceptedTerms.filter(x => !!selectedAgreement).map(invoice => (
                            <tr key={invoice.id}>
                                <td>
                                    <div className={"flex align-center"} style={{gap: 8}}>
                                        <ReportIcon fill={"#B386CF"}/>
                                        {t('acceptedTerm')} - {moment(invoice.validFrom).format('YYYY')}
                                    </div>
                                </td>
                                <td>
                                    <div className={"flex align-center clickable"} style={{gap: 8}} onClick={() => downloadContract(invoice)}>
                                        <DownloadIcon />
                                        {t('downloadAcceptedTerms')}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                )}
            </Gap>
        </Box>
    )

}

export default AcceptedTermsPage