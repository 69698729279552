import The0MissionText from "../../Assets/Icons/V2/The0MissionText";
import styles from "./Footer.module.scss";
import LinkedInIcon from "../../Assets/Icons/LinkedInIcon";
import The0MissionIcon from "../../Assets/Icons/The0MissionIcon";
import { StyledText } from "Components/V2/Text";
import {Link} from "react-router-dom";
import FlexRow from "../../Components/FlexRow";
import Space from "../../Components/Space";
import FacebookIcon from "../../Assets/Icons/FacebookIcon";
import InstagramIcon from "../../Assets/Icons/InstagramIcon";

interface Props {
    contentMaxWidth?: number;
}
const Footer = (props: Props) => {

    return (
        <footer className={styles.container}>
            <div style={{maxWidth: props.contentMaxWidth ?? '100%'}}>
                <Link to={"/v2/dashboard"}>
                    <FlexRow align={"center"}>
                        <The0MissionIcon width={45} height={45} variant={"light"} />
                        <The0MissionText width={174} height={20} />
                    </FlexRow>
                </Link>
                <hr/>
                <div className={styles.content}>
                    <StyledText type={"Caption"} color={"muted"}>
                        Copyright ©{new Date().getFullYear()}. The 0-Mission
                    </StyledText>
                    <Space size={"small"}>
                        <a href={"https://www.linkedin.com/company/the-0-mission/"} target={"_blank"}
                           rel={"noreferrer"}>
                            <LinkedInIcon/>
                        </a>
                        <a href={"https://www.facebook.com/profile.php?id=100086385047751"} target={"_blank"}
                           rel={"noreferrer"}>
                            <FacebookIcon/>
                        </a>
                        <a href={"https://www.instagram.com/the0mission/"} target={"_blank"}
                           rel={"noreferrer"}>
                            <InstagramIcon/>
                        </a>
                    </Space>
                </div>
            </div>
        </footer>
    )

}

export default Footer