const InstagramIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.86965 2.12756C6.24177 2.20101 4.8308 2.59903 3.69148 3.73354C2.54818 4.87401 2.15514 6.29041 2.08151 7.90136C2.03574 8.90684 1.76808 16.503 2.54421 18.4952C3.0676 19.8391 4.09846 20.8724 5.4547 21.3974C6.08754 21.6436 6.80994 21.8104 7.86966 21.859C16.7305 22.26 20.0151 22.0416 21.4002 18.4952C21.6459 17.8639 21.8151 17.1423 21.8619 16.0852C22.2668 7.20165 21.7962 5.27609 20.2519 3.73359C19.027 2.51173 17.5862 1.67996 7.86959 2.12761M7.95303 20.0723C6.98287 20.0287 6.45649 19.8669 6.10524 19.7309C5.22165 19.3875 4.55796 18.7264 4.21666 17.848C3.62561 16.3343 3.82163 9.14509 3.87437 7.98179C3.92611 6.84231 4.15696 5.8011 4.96095 4.99712C5.95599 4.00454 7.24158 3.51818 15.9949 3.91323C17.1372 3.96484 18.181 4.19512 18.987 4.99712C19.9821 5.98969 20.4756 7.285 20.0736 16.0048C20.0298 16.9726 19.8676 17.4977 19.7313 17.848C18.8308 20.1558 16.7591 20.4764 7.95301 20.0724M16.0896 6.69446C16.0896 7.35155 16.6239 7.88555 17.2836 7.88555C17.9433 7.88555 18.4787 7.35155 18.4787 6.69446C18.4787 6.03738 17.9433 5.50337 17.2836 5.50337C16.6239 5.50337 16.0896 6.03738 16.0896 6.69446ZM6.86259 11.9928C6.86259 14.8078 9.15018 17.0897 11.9721 17.0897C14.794 17.0897 17.0816 14.8078 17.0816 11.9928C17.0816 9.17788 14.794 6.89695 11.9721 6.89695C9.15018 6.89695 6.86259 9.17788 6.86259 11.9928ZM8.65565 11.9928C8.65565 10.1665 10.1403 8.68457 11.9721 8.68457C13.804 8.68457 15.2886 10.1665 15.2886 11.9928C15.2886 13.8201 13.804 15.3021 11.9721 15.3021C10.1403 15.3021 8.65565 13.8201 8.65565 11.9928Z"
                  fill="#153629"/>
        </svg>

    )


}

export default InstagramIcon