import { Component } from "react";
import { log } from "Apis/BaseApi";
import * as React from "react";

interface Props {
    children: React.ReactNode;
}
class ErrorBoundary extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    
    componentDidCatch(error, info) {
        this.setState({
            hasError: true
        })
        
        log(error.message, error.stack, {
            componentStack: info.componentStack
        }).then();
    }
    
    render() {
        return this.props.children;
    }
}
export default ErrorBoundary;
