import {SVGProps} from "react";

const CircledSolarPanel = (props: SVGProps<any>) => {

    return (
        <svg width={props.width ?? 48} height={props.height ?? 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#D1B6E2"/>
            <path d="M32.8031 14.5265C32.5604 14.1786 32.1586 13.9805 31.7374 14.0015H21.332C20.8619 14.0321 20.409 14.1922 20.0228 14.4643C19.6367 14.7367 19.3323 15.1104 19.1421 15.5456L17.9878 18.3371L17.9856 18.3419L16.2973 22.424L16.2962 22.4266L15.14 25.2225L15.1404 25.2221C14.9349 25.6488 14.9561 26.151 15.1969 26.5582C15.4393 26.9061 15.8418 27.1046 16.2626 27.0832H22.1126V33.1749L21.0284 33.1753C20.8024 33.1753 20.6196 33.3598 20.6196 33.5877C20.6196 33.8152 20.8024 34 21.0284 34H29.4416C29.6675 34 29.8503 33.8152 29.8503 33.5877C29.8503 33.3598 29.6675 33.1753 29.4416 33.1753H25.8868V27.084H26.6678C27.1379 27.0534 27.5908 26.8936 27.977 26.6212C28.3631 26.3491 28.6678 25.975 28.8576 25.5398L30.0127 22.7469L30.0134 22.7454L32.8598 15.8626C33.0653 15.4362 33.0436 14.9341 32.8031 14.5265ZM28.0819 14.8262L26.733 18.0871H23.2979L24.6464 14.8262H28.0819ZM26.3925 18.9118L25.0435 22.1733L21.6081 22.1737L22.957 18.9122L26.3925 18.9118ZM19.8972 15.8626C20.0242 15.5773 20.2238 15.331 20.476 15.1487C20.7278 14.9668 21.0234 14.8553 21.3322 14.8262H23.761L22.4125 18.0871H18.977L19.8972 15.8626ZM18.6362 18.9118H22.0713L20.7223 22.1733H17.2872L18.6362 18.9118ZM16.2629 26.2593C16.1165 26.2733 15.9727 26.2144 15.8775 26.1014C15.7935 25.9224 15.8001 25.7133 15.895 25.5399L16.9461 22.9981H20.3812L19.0326 26.259L16.2629 26.2593ZM19.9184 26.2593L21.2669 22.9984H24.7024L23.3535 26.2593H19.9184ZM25.0695 33.1757H22.9304V27.084H25.0695V33.1757ZM28.1035 25.2229C27.9765 25.5082 27.7769 25.7546 27.5247 25.9368C27.2725 26.1187 26.9768 26.2302 26.6681 26.2593H24.2396L25.5886 22.9984H29.024L28.1035 25.2229ZM29.3644 22.1737H25.9294L27.2783 18.9122H30.7138L29.3644 22.1737ZM32.1057 15.5456L31.0546 18.0875L27.6191 18.0871L28.9681 14.8262H31.7375C31.8842 14.8122 32.028 14.8711 32.1236 14.9842C32.2076 15.1631 32.201 15.3722 32.1057 15.5456Z" fill="#6B507C" stroke="#6B507C" strokeWidth="0.7"/>
        </svg>

    )

}

export default CircledSolarPanel