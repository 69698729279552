import * as React from "react"
import styles from './The0MissionIcon.module.scss';


interface Props {
    variant?: 'dark' | 'light'
    spin?: true;
    width?: number;
    height?: number
}
const The0MissionIcon = (props: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310.399 299.997" height={props.height ?? '1em'} width={props.width ?? '1em'} className={`${props.spin && styles.spin}`}>
        <g fill={`${props.variant === 'light' ? '#d3d8cf' : "#153629"}`}>
            <path
                data-name="Path 10817"
                d="M201.374 59.472c.31 33.05-14.92 60-44.36 60.32-29.77.29-45.69-26.42-46-59.46s15.09-60.04 44.87-60.33c29.43-.28 45.17 26.43 45.49 59.47Zm-72.72-8.47c.23 24.44 8.33 61.54 30.7 61.33 15-.14 24.63-16.76 24.38-43.78-.24-24.55-8.51-61.5-30.73-61.29-14.95.14-24.61 16.93-24.35 43.74Z"
            />
            <path
                data-name="Path 10818"
                d="M72.694 85.232c31.52 9.91 52.49 32.74 43.66 60.82-8.93 28.4-39.24 35.29-70.77 25.38s-52.44-32.91-43.51-61.32c8.83-28.11 39.09-34.8 70.62-24.88Zm-30.49 66.55c23.32 7.33 61.1 11.09 67.82-10.25 4.49-14.29-8.33-28.6-34.11-36.71-23.48-7.38-61.16-10.93-67.82 10.25-4.49 14.29 8.5 28.65 34.11 36.71Z"
            />
            <path
                data-name="Path 10819"
                d="M57.294 215.452c19.17-26.91 47.37-39.8 71.34-22.73 24.26 17.28 21.44 48.28 2.27 75.15s-47.51 39.71-71.76 22.43c-23.97-17.07-21.02-47.93-1.85-74.85Zm53.88 49.55c14.17-19.91 29.43-54.69 11.2-67.67-12.19-8.68-29.77-.92-45.45 21.1-14.27 20.05-29.29 54.78-11.2 67.66 12.19 8.71 29.87.8 45.45-21.09Z"
            />
            <path
                data-name="Path 10820"
                d="M176.474 270.472c-19.68-26.55-23.22-57.35.43-74.87 23.92-17.73 52.49-5.49 72.17 21.06s23.08 57.45-.84 75.18c-23.65 17.52-52.08 5.16-71.76-21.37Zm63.79-35.92c-14.55-19.64-42.91-44.9-60.89-31.57-12 8.91-10.07 28 6 49.74 14.65 19.77 43.05 44.79 60.89 31.57 12.05-8.92 9.99-28.17-6-49.74Z"
            />
            <path
                data-name="Path 10821"
                d="M265.614 174.002c-31.33 10.51-61.71 4.36-71.07-23.55-9.47-28.22 11-51.61 42.34-62.12s61.77-4.2 71.23 24c9.37 27.96-11.11 51.19-42.5 61.67Zm-14.45-71.77c-23.16 7.77-55.95 26.97-48.84 48.19 4.77 14.19 23.55 18.24 49.17 9.64 23.34-7.82 55.9-27.1 48.84-48.15-4.76-14.19-23.71-18.19-49.17-9.65Z"
            />
        </g>
    </svg>
)

export default The0MissionIcon
