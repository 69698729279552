const FacebookIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.8213 22V13H16.5537L17 9H13.8213V7.052C13.8213 6.022 13.8476 5 15.2869 5H16.7447V2.14C16.7447 2.097 15.4925 2 14.2257 2C11.58 2 9.92341 3.657 9.92341 6.7V9H7V13H9.92341V22H13.8213H13.8213Z"
                  fill="#153629"/>
        </svg>
    )



}

export default FacebookIcon