type Props = {
    direction?: 'row' | 'column'
    justify?: 'space-between' | 'space-around' | 'start' | 'center' | 'end';
    align?: 'start' | 'center' | 'end';
    children: any;
    style?: any
    className?: any;
    onClick?: any;
    rest?: any;
}
const FlexRow = (props: Props) => {

    let justify;

    switch (props.justify) {
        case "space-between":
            justify = 'space-between';
            break;
        case "space-around":
            justify = 'space-around';
            break;
        case "start":
            justify = 'justify-start';
            break;
        case "center":
            justify = 'justify-center';
            break;
        case "end":
            justify = 'justify-end';
            break;
        default:
            justify = "start";
    }

    let align = 'align-center';

    switch (props.align) {
        case "start":
            align = 'align-start';
            break;
        case "center":
            align = 'align-center';
            break;
        case "end":
            align = 'align-end';
            break;
    }

    return (
        <div {...props} className={`w-100 flex ${align} ${justify} ${props.direction ?? 'row'} ${props.className} `} {...props.rest}>
            {props.children}
        </div>
    )
}

export default FlexRow;
