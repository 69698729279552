import FlexRow from "../../../Components/FlexRow";
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import {Trans, useTranslation} from "react-i18next";
import {AgreementTermsModel} from "../../../Apis/Models/AgreementTermsModel";
import React, {useState} from "react";
import Space from "../../../Components/Space";
import {useDeleteTerms} from "../../../Apis/Admin/TermsApi";
import useToast from "../../../Hooks/useToast";

type Props = {
    terms: AgreementTermsModel;
    button: React.ReactElement;
}
const DeleteTermsModal = (props: Props) => {

    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);

    const { showSuccessToast } = useToast();

    const { mutateAsync: deleteTermsMutation, isLoading: isDeletingTerms } = useDeleteTerms(props.terms.id);

    const deleteTerms = async () => {
        await deleteTermsMutation();

        setVisible(false);

        showSuccessToast(t('terms:termsDeleted'));
    }

    return (
        <>
            {React.cloneElement(props.button, {onClick: () => setVisible(true)})}
            <Modal title={t('terms:deleteTerms')} visible={visible} onCancel={() => setVisible(false)}>
                <Space>
                <div className={"w-100"}>
                        <Trans i18nKey="terms:deleteTermsConfirmation" values={{terms: props.terms.name, version: props.terms.version}} />
                    </div>

                    <FlexRow justify={"end"}>
                        <Button color={"danger"}  disabled={isDeletingTerms} onClick={deleteTerms} loading={isDeletingTerms}>{t('delete')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )

}

export default DeleteTermsModal