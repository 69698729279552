import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { AgreementTermsModel } from "Apis/Models/AgreementTermsModel";
import { CreateTermsModel } from "Apis/Models/CreateTermsModel";

export const ADMIN_TERMS_CACHE_KEY = 'ADMIN_TERMS_CACHE_KEY';
export const useTerms = () => {
    return useQuery<AgreementTermsModel[]>(ADMIN_TERMS_CACHE_KEY, async () => {
        return await request<AgreementTermsModel[]>({
            url: `/admin/terms`,
            method: 'GET',
        })
    });
}

export const useSaveTerms = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (agreementTerms: CreateTermsModel): Promise<AgreementTermsModel> => {
        return await request<AgreementTermsModel>({
            url: `/admin/terms`,
            method: 'POST',
            data: agreementTerms
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_TERMS_CACHE_KEY);
        }
    })
}

export const useDeleteTerms = (id: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (): Promise<void> => {
        return await request<void>({
            url: `/admin/terms/${id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_TERMS_CACHE_KEY);
        }
    })
}
