import FlexRow from "Components/FlexRow";
import styles from './PageLoader.module.scss';
import The0MissionIcon from "Assets/Icons/The0MissionIcon";

interface Props {
    shown: boolean;
    children: any;
}
const PageLoader = ({shown, children}: Props) => {
    
    return (
        <>
            <div className={`${styles.pageLoader} ${!shown ? styles.hidden :''}`}>
                <FlexRow justify={'center'} direction={"column"} align={"center"}>
                    <The0MissionIcon width={200} height={200} spin />
                </FlexRow>
            </div>
            <div className={`${styles.content} ${shown ? styles.hidden :''}`}>
                {!shown && (
                    <>
                        {children}
                    </>
                )}
            </div>
        </>
    )
}

export default PageLoader
