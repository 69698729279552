import AgreementUsageChart from "Pages/Agreements/Agreement/Components/AgreementUsageChart";
import {useEffect, useRef, useState} from "react";
import styles from './DashboardPage.module.scss';
import AgreementComparisonChart from "Pages/Agreements/Agreement/Components/AgreementComparisonChart";
import { useEnergiData } from "Apis/EnergiDataApi";
import { useTranslation } from "react-i18next";
import DownloadIcon from "Assets/Icons/DownloadIcon";
import ArrowIcon from "Assets/Icons/ArrowIcon";
import ExternalLinkIcon from "Assets/Icons/ExternaLinkIcon";
import { AgreementModel } from "Apis/Models/AgreementModel";
import ResourceLink, {ResourceLinkProps} from "Components/ResourceLink";
import { useSolarParks } from "Apis/SolarParkApi";
import {getContractPdf} from "../../../Apis/AgreementApi";
import {useNavigate} from "react-router-dom";
import {useAuthProvider} from "../../../Providers/AuthProvider";

interface IProps {
    agreement: AgreementModel;
}
const DashboardPage = (props: IProps) => {
    
    const { t } = useTranslation();
    const { agreement } = props;
    const { id: agreementId } = agreement;
    
    const chartsDivRef = useRef<HTMLDivElement>(null)
    
    const { data: energiData } = useEnergiData();
    const { data: solarParks } = useSolarParks();

    const { userContext } = useAuthProvider();

    const navigate = useNavigate();

    useEffect(() => {
        if (userContext?.permissions.includes("Admin")) {
            return;
        }

        if (!!agreement && agreement.agreementStatus !== "Active") {
            navigate("/agreements")
        }
    }, [userContext, agreement, navigate])
    
    const [resources] = useState<ResourceLinkProps[]>([
        {
            url: `/agreements/${agreementId}/communication/parkImages/`,
            icon: <DownloadIcon />,
            title: t('resources:parkImagesTitle'),
            text: t('resources:parkImagesText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/ParkImage.png"} />
        },
        {
            url: `/agreements/${agreementId}/communication/logo/`,
            icon: <DownloadIcon />,
            title: t('resources:logoTitle'),
            text: t('resources:logoText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/icon.png"} />
        },
        {
            url: `/agreements/${agreementId}/communication/`,
            icon: <ArrowIcon style={{transform: 'rotate(180deg)'}} />,
            title: t('resources:communicationTitle'),
            text: t('resources:communicationText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/megaphone.png"} />
        },
        {
            openInNewTab: true,
            url: `https://the0mission.dk`,
            icon: <ExternalLinkIcon  />,
            title: t('resources:blogTitle'),
            text: t('resources:blotText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/blog.png"} />
        },
        {
            url: `/agreements/${agreementId}/invoices`,
            icon: <DownloadIcon />,
            title: t('resources:invoicesTitle'),
            text: t('resources:invoicesText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/invoices.png"} />
        },
        {
            onClick: async () => await getContractPdf(agreement),
            icon: <DownloadIcon />,
            title: t('resources:contractTitle'),
            text: t('resources:contractText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/contract.png"} />
        },
        {
            url: `/agreements/${agreementId}/communication/annualReports`,
            icon: <DownloadIcon />,
            title: t('resources:yearlyReportTitle'),
            text: t('resources:yearlyReportText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/yearlyReport.png"} />
        },
        {
            url: `/agreements`,
            icon: <DownloadIcon />,
            title: t('resources:addMoreVatNumbersTitle'),
            text: t('resources:addMoreVatNumbersText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/plus.png"} />
        },
    ]);
    
    return (
        <>
            <div className={styles.graphGrid}>
                <div>
                    {!!solarParks && (
                        <AgreementComparisonChart agreement={agreement} width={chartsDivRef.current?.clientWidth} solarPark={solarParks[0]} />
                    )}
                </div>
                <div ref={chartsDivRef}>
                    <AgreementUsageChart agreement={agreement} width={chartsDivRef.current?.clientWidth} />
                </div>
            </div>
    
            <div className={styles.statsGrid}>
                <div>
                    <div className={styles.statTitle}>
                        {t('agreement:C02StatsTitle')}
                    </div>
                    <div>
                        {t('agreement:C02StatsText')}
                    </div>
                </div>
                <div className={styles.stat}>
                    <div>
                        {energiData?.c02EmissionDK1} g/kWh
                    </div>
                    <div>
                        {t('agreement:currentCO2Emission')}
                    </div>
                </div>
                <div className={styles.stat}>
                    <div>
                        {energiData?.c02EmissionLocation} g/kWh
                    </div>
                    <div>
                        {t('agreement:locationCO2Emission')}
                    </div>
                </div>
                <div className={styles.stat}>
                    <div>
                        {energiData?.c02EmissionMarket} g/kWh
                    </div>
                    <div>
                        {t('agreement:marketCO2Emission')}
                    </div>
                </div>
            </div>
            
            <div className={styles.resources}>
                <div>
                    {t('resources:resources')}
                </div>
                <div className={styles.resourcesGrid}>
                    {resources.map((resource, index) => (
                        <ResourceLink key={index} {...resource} />
                    ))}
                </div>
            </div>
            
        </>
    )
}

export default DashboardPage
